<template>
<section class="poma_m">
   <div class="banner">
      <p :class="$i18n.locale == 'en' ? 'wrap' : 'nowrap'">{{ $t("PC.Product.POMA.sgfs7b") }}</p>
      <a :href="pomaUrl">
         <span>{{ $t("PC.Product.POMA.rms9wl") }}</span>
      </a>
      <router-link class="price" to="POMAPrice">
         <span>{{ $t("PC.Product.POMA.bumk5q") }}</span>
      </router-link>
      <img class="bg" src="../../../assets/zh/images-mobile//suanfas_banner@2x(1).png" alt="" />
   </div>
   <ul class="contain_1">
      <p class="x">
         {{ $t("PC.Product.POMA.75kpe2") }}
      </p>
      <p class="big_title">{{ $t("PC.Product.POMA.c15oic") }}</p>
      <li>
         <img src="../../../assets/zh/images-mobile//poma_img1@2x.png" alt="" />
         <div class="content_box cb1">
            <p class="title">{{ $t("PC.Product.POMA.f84a1h") }}</p>
            <p class="text">
               {{ $t("PC.Product.POMA.732t2f") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../../assets/zh/images-mobile//poma_img2@2x.png" alt="" />
         <div class="content_box cb2">
            <p class="title">{{ $t("PC.Product.POMA.oa6qtt") }}</p>
            <p class="text">
               {{ $t("PC.Product.POMA.jsz6xu") }}
            </p>
         </div>
      </li>
      <li class="last_li">
         <img src="../../../assets/zh/images-mobile//poma_img3@2x.png" alt="" />
         <div class="content_box cb3">
            <p class="title">{{ $t("PC.Product.POMA.k99vn8") }}</p>
            <p class="text">
               {{ $t("PC.Product.POMA.9x9qh6") }}
            </p>
         </div>
      </li>
   </ul>
   <div class="contain_2">
      <p>{{ $t("PC.Product.POMA.p2pydm") }}</p>
      <div class="bigbox">
         <div class="box">
            <img src="../../../assets/zh/images-mobile//poma_img4@2x.png" alt="" />
            <article class="mask">
               {{ $t("PC.Product.POMA.1328mp") }}
            </article>
         </div>
         <div class="box">
            <img src="../../../assets/zh/images-mobile//poma_img5@2x.png" alt="" />
            <article class="mask">
               {{ $t("PC.Product.POMA.lwoc5e") }}
            </article>
         </div>
         <div class="box">
            <img src="../../../assets/zh/images-mobile//poma_img6@2x.png" alt="" />
            <article class="mask">
               {{ $t("PC.Product.POMA.zebirx") }}
            </article>
         </div>
         <div class="box">
            <img src="../../../assets/zh/images-mobile//poma_img7@2x.png" alt="" />
            <article class="mask">
               {{ $t("PC.Product.POMA.vaq89k") }}
            </article>
         </div>
         <div class="box">
            <img src="../../../assets/zh/images-mobile//poma_img8@2x.png" alt="" />
            <article class="mask">
               {{ $t("PC.Product.POMA.h5fbw8") }}
            </article>
         </div>
         <div class="box">
            <img src="../../../assets/zh/images-mobile//poma_img9@2x.png" alt="" />
            <article class="mask">
               {{ $t("PC.Product.POMA.t4h4oj") }}
            </article>
         </div>
      </div>
   </div>
   <div class="contain_3">
      <img :src="$t('locale')=='zh'?suanfas:suanfas_en" alt="" />
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "../components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         pomaUrl: Global.POMA_URL,
         suanfas: require('../../../assets/zh/images-mobile//suanfas_img10@2x.png'),
         suanfas_en: require('../../../assets/en/images-mobile//suanfas_img10@2x.png'),
      };
   },
};
</script>

<style lang="scss" scoped>
.poma_m {
   margin-top: 48px;
   word-break: break-word !important;

   .banner {
      position: relative;

      p {
         font-size: 14px;
         font-family:   PingFangSC-Semibold, PingFang SC;
         font-weight: 600;
         color: #fff;
         line-height: 20px;
         position: absolute;
         left: 30px;
         top: 42px;
         z-index: 2;
      }
      .wrap{
         width: 190px;
         top: 28px;
      }

      a {
         position: absolute;
         top: 74px;
         left: 30px;
         width: 60px;
         height: 19px;
         border-radius: 1px;
         border: 1px solid #fff;

         span {
            color: #fff;
            white-space: nowrap;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(0.7);
            transform-origin: center center;
         }
      }

      .price {
         position: absolute;
         top: 74px;
         left: 103px;
         background: transparent;
         border: 1px solid #ffffff;
         border-radius: 1px;
         width: 60px;
         height: 19px;

         span {
            color: #fff;
            display: inline-block;
            width: 100%;
            font-family:   PingFangSC-Semibold, PingFang SC;
            text-align: center;
            line-height: 18px;
            vertical-align: top;
            font-size: 8px;
            transform: scale(0.7);
            transform-origin: center center;
         }
      }

      img.bg {
         width: 100%;
         height: 150px;
      }
   }

   ul.contain_1 {
      box-sizing: border-box;
      padding: 20px;

      p.x {
         font-size: 12px;
         color: #333;
         line-height: 24px;
         letter-spacing: 0.2px;
         padding-bottom: 16px;
      }

      &>p.big_title {
         color: #333;
         text-align: center;
         padding-bottom: 8px;
         font-size: 16px;
         line-height: 22px;
      }

      li {
         width: 335px;
         overflow: hidden;
         margin-bottom: 16px;

         img {
            width: 335px;
            height: 180px;
         }

         .content_box {
            padding: 12px 16px 18px;
            background-color: #f5f6f9;

            p.title {
               color: #333;
               font-size: 14px;
               line-height: 20px;
               padding-bottom: 8px;
               font-weight: 600;
            }

            .text {
               color: #666;
               font-size: 12px;
               line-height: 18px;
               width: 100%;
               font-size: 12px;
               line-height: 21px;
            }

            .more {
               display: inline-block;
               margin-top: 12px;
               width: 72px;
               height: 25px;
               border: 1px solid #666666;
               font-size: 10px;
               font-family:   PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               color: #666666;
               line-height: 24px;
               text-align: center;
            }
         }
      }

      .last_li {
         margin-bottom: 0;
      }
   }

   div.contain_2 {
      width: 100%;
      background: #f5f6f9;
      padding: 16px 20px 11px;

      p {
         font-size: 16px;
         font-weight: 500;
         color: #333;
         line-height: 22px;
         text-align: center;
         margin-bottom: 12px;
      }

      .bigbox {
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         align-items: center;

         div.box {
            width: 163px;
            height: 106px;
            position: relative;
            margin-bottom: 9px;

            img {
               width: 163px;
               height: 106px;
            }

            .mask {
               width: 163px;
               height: 32px;
               background-color: rgba($color: #000, $alpha: 0.58);
               position: absolute;
               bottom: 0;
               font-size: 14px;
               line-height: 32px;
               color: #fff;
               text-align: center;
            }
         }
      }
   }

   div.contain_3 {
      img {
         width: 100%;
         height: 254px;
      }
   }
}
</style>
